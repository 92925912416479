<template>
  <Toasts />
  <div class="sticky top-0 z-50 min-h-16">
    <NavigationBar
      class="fixed top-0 lg:relative"
      :config="{
        applicationName: null,
        environmentBadge: null,
        salesLinks: null,
        lettingsLinks: null,
        isAdmin: false,

        printAdminLinks: null,
        identityAdminLinks: null,
        emailLinks: null,
        insightsLinks,
        printSalesLinks: null,
        printLettingsLinks: null,
        accountLinks: null,
        mobileLinks: [
          {
            icon: null,
            title: 'MobileUserDropdownMenu',
            description: '',
            badge: null,
            link: null,
            isInertia: null,
            subLinks: [
              {
                title: 'Logout',
                badge: null,
                link: '/logout',
                isInertia: false,
              }
            ],
          },
        ],
        userLinks: null,
        credits: null,
        fullBranchName: null,
        userName: $page.props.auth.user?.name ?? '',
        showSalesUpsellBanner: null,
        showLettingsUpsellBanner: null,
        csrfToken: $page.props.csrf_token,
      }"
      :logout-link="route('logout')"
      :has-insights-enabled="true"
      :user-uuid="page.props.auth.user?.spectreUserId ?? ''"
      :environment="page.props.app.env"
      @inertia-link-click="handleInertiaLinkClick"
    />
  </div>
  <slot />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { router, usePage } from '@inertiajs/vue3';
import Toasts from '@/Components/Toasts.vue';
import { NavigationBar } from '@agentsoftware/spectre-ui';
import NavigationLinks from '@/Config/NavigationLinks';
import { trackUser } from '@/Support/MixpanelTracking';

const page = usePage();

const insightsLinks = computed(() => (page.props.app.env === 'local' ? NavigationLinks : null));

const handleInertiaLinkClick = (link: string) => {
  router.get(link);
};

if (page.props.auth.user?.id) {
  trackUser(
    page.props.auth.user.id,
    {
      $email: page.props.auth.user.email,
      $name: page.props.auth.user.name,
    },
  );
}
</script>

<style>
.mapboxgl-marker {
  outline: none;
}
</style>
