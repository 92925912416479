import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { usePage } from '@inertiajs/vue3';
import ToastData = App.Data.ToastData;
import ToastType = App.Enums.ToastType;

export default defineStore('toast-queue', () => {
  const page = usePage();
  const queue = ref<ToastData[]>([]);
  const duration = 3000;

  const dequeue = (): ToastData | null => {
    if (queue.value.length === 0) {
      return null;
    }

    return queue.value.shift() || null;
  };

  const enqueue = (label: string, type: ToastType = 'success') => {
    if (type === 'error' && !label) {
      queue.value.push({
        label: 'Something went wrong, please try again.',
        type,
      });
      return;
    }

    queue.value.push({
      label,
      type,
    });
  };

  watch(() => page.props.flash.toasts, (newValue: ToastData[]) => {
    newValue.forEach((toast) => enqueue(toast.label, toast.type));
  });

  return {
    enqueue,
    dequeue,
    duration,
    queue,
  };
});
