import {
  faBuildingMagnifyingGlass,
  faMemoCircleCheck,
  faImageUser,
  faBrowsers,
  faGears,
} from '@awesome.me/kit-644ec404bf/icons/classic/light';

export default [
  {
    icon: faBuildingMagnifyingGlass,
    title: 'Search and Create',
    description: 'Explore, research, and create reports on millions of properties across the UK',
    badge: null,
    link: '/search',
    subLinks: null,
    isInertia: true,
  },
  {
    icon: faMemoCircleCheck,
    title: 'Saved Property Reports',
    description: 'View, edit, and share your previously created reports',
    badge: null,
    link: '/reports',
    subLinks: null,
    isInertia: true,
  },
  {
    icon: faImageUser,
    title: 'Leads',
    description: 'View your leads generated by lead capture reports and landing pages',
    badge: null,
    link: '/leads',
    subLinks: null,
    isInertia: true,
  },
  {
    icon: faBrowsers,
    title: 'Landing Pages',
    description: 'Create and manage your landing pages for instant valuations and more',
    badge: null,
    link: '/instant-valuations',
    subLinks: null,
    isInertia: true,
  },
  {
    icon: faGears,
    title: 'Settings (local only)',
    description: 'Manage your insights settings for your reports and preferences',
    badge: null,
    link: '/settings/report-content/edit',
    subLinks: null,
    isInertia: true,
  },
];
