import mixpanel from 'mixpanel-browser';
import { usePage } from '@inertiajs/vue3';

const page = usePage();

const mixpanelInitialised = () => page.props.app.env === 'production';

const trackEvent = (eventName: string, eventProperties: Record<string, string | null>) => {
  if (!mixpanelInitialised()) {
    return;
  }

  mixpanel.track(eventName, eventProperties);
};

const trackUser = (userID: string, properties: Record<string, string>) => {
  if (!mixpanelInitialised()) {
    return;
  }

  mixpanel.identify(userID);
  mixpanel.people.set(properties);
};

export {
  trackEvent,
  trackUser,
};
