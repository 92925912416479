<template>
  <div
    v-if="queue.length"
    aria-live="assertive"
    class="z-[60] pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
  >
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <Toast
        v-for="message in queue"
        :key="message.label"
        :toast="message"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import useToastQueue from '@/Stores/ToastQueue';
import Toast from '@/Components/Toast.vue';

const { queue } = useToastQueue();
</script>
